import Evaluation from "@/view/evaluation/template/evaluation.vue";
import Evaluate from "@/view/shared/questionnaire/rate.vue";
import Rate from '@/view/shared/questionnaire/evaluate.vue';
import Annexes from "@/view/shared/questionnaire/annexes.vue";

const routes = [
    {
        path: '/evaluations/:id',
        name: 'evaluations_id',
        component: Evaluation,
        props: {id: null, showMenu: false,background:true, otherTitle: 'evaluation'},
    },
    {
        path: '/evaluation/:type/:id/:actual_id/:user_id/:extra_id/:complete',
        name: 'evaluation_questionnaire_map',
        component: Rate,
        props: {type: null, user_id: null, id: null, actual_id: null, extra_id: null, complete: null, showMenu: false, background: true, questionnaireBackground: true, otherTitle: 'evaluation'},
    },
    {
        path: '/evaluation/:type/:id/:actual_id/:extra_id',
        name: 'evaluation_questionnaire',
        component: Rate,
        props: {id: null, actual_id: null, type: null, extra_id: null, showMenu: false, background: true, questionnaireBackground: true, otherTitle: 'evaluation'},
    },
    {
        path: '/rate/:type/:id/:actual_id/:extra_id',
        name: 'rate_questionnaire',
        component: Evaluate,
        props: {id: null, actual_id: null, type: null, extra_id: null, showMenu: false, background: true, questionnaireBackground: true, otherTitle: 'evaluation'},
    },
    {
        path: '/rate/:type/:id/:actual_id/:user_id/:extra_id/:complete',
        name: 'rate_questionnaire_map',
        component: Evaluate,
        props: {type: null, user_id: null, id: null, actual_id: null, extra_id: null, complete: null, showMenu: false, background: true, questionnaireBackground: true, otherTitle: 'evaluation'},
    },
    {
        path: '/annexes/attached/agrupator/:type/:id/:extra_id',
        name: 'evaluation_annexes',
        component: Annexes,
        props: {id: null, type: null, extra_id: null, showMenu: false, background: true, questionnaireBackground: true, otherTitle: 'evaluation'},
    }
];

export default routes;