var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-content-global-questionnaire"},[_c('div',{staticClass:"question-body"},[_c('div',{staticClass:"gh-content-global"},[(_vm.haveData)?_c('form',{attrs:{"id":"form_joint_map"}},[_c('GhChartTable',{ref:"RefJointMap",attrs:{"extratable":{
                            id: 'joint_map',
                            more: require('@/assets/questionnaire/arrow-down.svg'),
                            less: require('@/assets/questionnaire/arrow-up.svg'),
                            pointer: {
                                colors: [
                                    '#C7C7C7',
                                    '#F58449',
                                    '#005392'
                                ],
                                title: [
                                    'for_start',
                                    'in_progress',
                                    'finish'
                                ]
                            }
                        },"header":_vm.headerChart,"data":_vm.getterQuestionnaireJointMap.data,"footer":[],"type":_vm.getterQuestionnaireJointMap.type,"evaluator":_vm.getterQuestionnaireJointMap.is_evaluator,"leader":_vm.getterQuestionnaireJointMap.is_leader,"evaluation_finished":_vm.getterQuestionnaireJointMap.evaluation_finished,"states":_vm.statesChart},on:{"advice":function($event){''},"columnAdvice":_vm.columnAdvice,"columnPunctuation":_vm.columnPunctuation},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(itemProp.actions !== undefined && itemProp.actions.opportunities)?[_c('GhAction',{staticClass:"color-light-blue T-subtitle-second underline-light-blue",staticStyle:{"margin-left":"10px"},attrs:{"dataction":{
                                        id: 'modify_action_' + itemProp.id,
                                        text: _vm.$t('selecting'),
                                        class: 'T-subtitle-colored',
                                        icon: require('@/assets/gh_new_dessign/tick.svg')
                                    }},on:{"click":function($event){return _vm.changeOportunitiesOrHardPoints(itemProp)}}})]:_vm._e()]}},{key:"component",fn:function({itemProp}){return [(itemProp.actions !== undefined && itemProp.actions.valoration)?[_c('GhInputTextField',{ref:'RefValoration' + itemProp.id,attrs:{"datalabel":{
                                        id: 'label_joint_evaluations_valoration_' + itemProp.id,
                                        text: '',
                                        style: '',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'input_joint_evaluations_valoration_' + itemProp.id,
                                        name: 'joint_evaluations[' + itemProp.id + '][valoration]',
                                        style: 'width: 100%',
                                        type: 'text',
                                        value: _vm.valorations['uuid_' + itemProp.id]['valoration'],
                                        required: true,
                                        label_required: itemProp.title
                                    }},on:{"keyup":function($event){return _vm.persistValoration(itemProp.id)}}})]:_vm._e()]}}],null,false,183203083)})],1):_vm._e(),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"15px"}},[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                            id: 'btn_back_joint_map',
                            text: _vm.$t('back')
                        },"title":_vm.$t('back')},on:{"click":function($event){return _vm.router.go(-1)}}}),(_vm.getterQuestionnaireJointMap.is_leader)?[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                                id: 'btn_save_joint_map',
                                text: _vm.$t('save')
                            },"title":_vm.$t('save'),"disabled":_vm.disabledSave},on:{"click":function($event){return _vm.setLiderMap('save')}}}),_c('GhButton',{attrs:{"datainput":{
                                id: 'btn_finish_joint_map',
                                text: _vm.$t('finish'),
                                class: 'T19 container-md_button_content button-questionnaire-primary'
                            },"title":_vm.disabledFinish ? _vm.$t('to_finish_need_all_required') : _vm.$t('finish'),"disabled":_vm.disabledFinish},on:{"click":function($event){return _vm.setLiderMap('finish')}}})]:_vm._e()],2),(_vm.haveData)?_c('div',{key:'ADVICE' + _vm.refreshPopup},[_c(_vm.popups.advice,{tag:"component",attrs:{"edit":_vm.getterQuestionnaireJointMap.is_leader,"id":_vm.currentIdentifier}})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }