<template>
    <div style="width: 100%; display: flex; justify-content: center;">
        <template v-if="haveData">
            <div class="card-container" style="padding: 20px; max-width: 1500px;">
                <template v-for="(itemCard, keyCard) in getterContractedPrograms">
                    <div :key="'KEY'+ keyCard">
                        <template v-if="!itemCard.finished">
                            <router-link :to="{name: 'initial_itinerary_window', params: {
                                type: 'initial',
                                id: itemCard.id,
                                extra_id: itemCard.company_id !== undefined && itemCard.company_id !== null && itemCard.company_id !== '' ? itemCard.company_id : 0,
                                read_only: itemCard.pending_evaluate
                            }}">
                                <component :is="components.card" :keyCard="keyCard" :itemCard="itemCard" :showButton="true"/>
                            </router-link>
                        </template>
                        <template v-else>
                            <div @click="downloadPDF(itemCard)">
                                <component :is="components.card" :keyCard="keyCard" :itemCard="itemCard" :showButton="true"/>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="centered" style="padding-bottom: 10px; margin-top: 15px;">
                <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    import card from '../../template/card.vue';

    export default {
        name: 'contracted_programs',
        computed: {
            ...mapGetters(['getterContractedPrograms'])
        },
        data(){
            return{
                haveData: false,
                components: {
                    card: card
                }
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getContractedPrograms', '', {root: true});

                this.haveData = true;
            }
        },
        methods: {
            async downloadPDF(item){
                await this.$store.dispatch('getPDFGlobalMap', {
                    id: item.id,
                    type: 'initial'
                }, {root: true}).then((response) => {
                    if(response.data !== undefined && response.data !== null && response.data !== '') {
                        const link = document.createElement('a');
                        link.id = 'download_report_pdf';

                        var filename = '';
                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const match = contentDisposition.match(/filename="(.+)"/);
                            if (match && match[1]) {
                                filename = match[1];
                            }
                        }

                        const blob = new Blob([response.data], {type: 'application/pdf'});
                        link.href = URL.createObjectURL(blob);
                        link.download = filename;

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        URL.revokeObjectURL(link.href);
                    }
                });
            }
        }
    }
</script>