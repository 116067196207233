var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":'_float_window'}},[_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'crm_priority',
                title: _vm.$t('priority'),
                num_results: '',
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_priority"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        id: 'label_name_priority',
                                        text: _vm.$t('name_priority') + '*',
                                        style: 'margin-right: 10px; width: 192px; min-width: 192px !important; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'input_name_priority',
                                        name: 'priority[name]',
                                        type: 'text',
                                        value: _vm.ConfigPriorities.data && _vm.ConfigPriorities.data.name ? _vm.ConfigPriorities.data.name : '',
                                        required: true
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"192px","min-width":"192px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name_priority') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"50%","min-width":"100px"}},[_vm._v(_vm._s(_vm.ConfigPriorities.data && _vm.ConfigPriorities.data.name ? _vm.ConfigPriorities.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        id: 'label_description_priority',
                                        text: _vm.$t('description_priority') + '*',
                                        style: 'margin-right: 10px; width: 192px; min-width: 192px !important; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'input_description_priority',
                                        name: 'priority[description]',
                                        type: 'text',
                                        value: _vm.ConfigPriorities.data && _vm.ConfigPriorities.data.description ? _vm.ConfigPriorities.data.description : '',
                                        required: true
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"192px","min-width":"192px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description_priority') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"50%","min-width":"100px"}},[_vm._v(_vm._s(_vm.ConfigPriorities.data && _vm.ConfigPriorities.data.description ? _vm.ConfigPriorities.data.description : ''))])]],2),(_vm.ConfigPriorities.data && _vm.ConfigPriorities.data.groups && _vm.ConfigPriorities.data.groups.length !== 0)?_c('div',_vm._l((_vm.ConfigPriorities.data.groups),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"192px","min-width":"192px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t(item.name)))])]),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                                id: 'time_priority_response_' + item.id,
                                                text: _vm.$t('response_time') + ' (h)',
                                                style: 'margin-right: 10px; width: 192px; min-width: 192px; text-align: right;',
                                                class: 'T15_b'
                                            },"datainput":{
                                                name: 'priority[groups]['+item.id+'][response_time]',
                                                style: 'width:20%; ',
                                                type: 'int',
                                                value: item.response_time !== undefined ? item.response_time : '',
                                                required: false
                                            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                                id: 'time_priority_resolution_'+item.id,
                                                text: _vm.$t('time_resolution')+ ' (h)',
                                                style: 'width: 88px; min-width: 208px !important; text-align: right;',
                                                class: 'T15_b'
                                            },"datainput":{
                                                name: 'priority[groups]['+item.id+'][solution_time]',
                                                style: 'width:20%;',
                                                type: 'int',
                                                value: item.solution_time !== undefined ? item.solution_time : '',
                                                required: false
                                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"192px","min-width":"192px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('response_time') + ' (h)'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"44%","min-width":"100px"}},[_vm._v(_vm._s(item.response_time !==undefined ? item.response_time : ''))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"88px","min-width":"208px","text-align":"right","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('time_resolution')+ ' (h)'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"44%","min-width":"100px"}},[_vm._v(_vm._s(item.solution_time !== undefined ? item.solution_time : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                                id:'time_priority_percentage_'+item.id,
                                                text: _vm.$t('percentage'),
                                                style: 'width: 192px; min-width: 192px; text-align: right;',
                                                class: 'T15_b'
                                            },"datainput":{
                                                id: 'input_priority_percentage_'+item.id,
                                                name: 'priority[groups]['+item.id+'][percentage]',
                                                style: 'width:20%; max-height: 17px;',
                                                type: 'int',
                                                limitNumMin: 0,
                                                limitNumMax: 100,
                                                value: item.percentage !== undefined ? item.percentage : ''
                                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"192px","min-width":"192px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('percentage')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"50%","min-width":"100px"}},[_vm._v(_vm._s(item.percentage !== undefined ? item.percentage : ''))])]],2)])}),0):_vm._e()])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_priority_save',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('save')
                }},on:{"click":function($event){return _vm.setPriority()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_priority_close',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('back')
                }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }