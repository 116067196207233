<template>
    <div :id="'_float_window'" >
        <template v-if="$root._route.params.add">
            <GhLocation
                :dataLocation="{showButton: Login.perm_support, secondWindow:true, showLoader:'OK', text: $t('create_incidence') }"
                :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
                @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{add:true}),'new_incidence')"
            />
        </template>
        <div class="content_page">
            <form id="form_support_incidence">
                <input v-if="$root._route.params.origin_id" type="hidden" name="incidence[origin_ticket_uuid]" :value="$root._route.params.origin_id">
                <GhNoDropSelector
                    :datablock="{
                        id: 'crm_support_incidence',
                        title: $root._route.params.add ? $t('incidence') : $t('incidence') + ' ' + (TabSupport.data.ticket_reference !== undefined ? TabSupport.data.ticket_reference : ''),
                        num_results: '',
                    }"
                >
                    <template v-slot:content>
                        <div class="gh-content-global" v-if="haveData">
                            <div class="gh-row-content">
                                <label :class="$root._route.params.add ? 'gh_text_field-label T15_b' : 'gh_text_field-label T15' " style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('opening_by') + ':'}}</label>
                                <label :class="$root._route.params.add ? 'gh_text_field-label T15' : 'gh_text_field-label T15_b' " style="margin-right: 10px; width: 18%; min-width: 260px !important;">{{TabSupport.data.created_by !== undefined ? TabSupport.data.created_by : ''}}</label>
                                <label :class="$root._route.params.add ? 'gh_text_field-label T15_b' : 'gh_text_field-label T15' " style="margin-right: 10px; margin-right: 10px; width: calc((100% - 169px) - (((100% - 165px) * 8) / 12)); text-align: right; text-align: right;">{{$t('email') + ':'}}</label>
                                <label :class="$root._route.params.add ? 'gh_text_field-label T15' : 'gh_text_field-label T15_b' " style="margin-right: 10px; width: 20%; min-width: 260px !important;">{{TabSupport.data.email !== undefined ? TabSupport.data.email : ''}}</label>
                            </div>
                            <div class="gh-row-content">
                                <label :class="$root._route.params.add?'gh_text_field-label T15_b':'gh_text_field-label T15'" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('company') + ':'}}</label>
                                <label :class="$root._route.params.add?'gh_text_field-label T15':'gh_text_field-label T15_b'" style="margin-right: 10px; width: 18%; min-width: 260px !important;">{{TabSupport.data.company !== undefined ? TabSupport.data.company : ''}}</label>
                                <template v-if="!$root._route.params.add">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: calc((100% - 170px) - (((100% - 165px) * 8) / 12)); text-align: right;">{{$t('date_creation') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 260px !important;">{{TabSupport.data.created_date !== undefined ? TabSupport.data.created_date : '' }}</label>
                                </template>
                            </div>
                            <div class="gh-row-content" id="id_category_add" v-if="$root._route.params.add">
                                <template v-if="$root._route.params.origin_id !== undefined">
                                    <label :class="$root._route.params.add ? 'gh_text_field-label T15_b' : 'gh_text_field-label T15' " style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('category') + ':'}}</label>
                                    <label :class="$root._route.params.add ? 'gh_text_field-label T15' : 'gh_text_field-label T15_b' " style="margin-right: 10px; width: 18%; min-width: 260px !important;">{{Object.values(TabSupport.data.category).join(', ')}}</label>
                                    <input type="hidden" name="incidence[category]" :value="Object.keys(TabSupport.data.category)[0]">
                                </template>
                                <template v-else>
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_incidence_category',
                                            class: 'gh_text_field-label T15_b',
                                            subtitle: $t('select_category') + ':',
                                            text: $t('category'),
                                            label: $t('category') + ':',
                                            type: 'radio',
                                            columns: 3,
                                            style: 'margin-left: 60px;',
                                        }"
                                        :datainput="{
                                            id: 'value_certificate_activity',
                                            name: 'incidence[category]'
                                        }"
                                        :feed="getFeeds.feed_support_categories"
                                        ref="RefSelectorCategory"
                                        @accept="getAssign"
                                    />
                                </template>
                            </div>
                            <div class="gh-row-content" v-if="$root._route.params.add" :key="refreshAssign">
                                <template v-if="$root._route.params.origin_id !== undefined">
                                    <label :class="'gh_text_field-label T15_b'"  style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('assign_to') + ':'}}</label>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 80%; min-width: 260px !important;">{{Object.values(TabSupport.data.assigned_to).join(', ')}}</label>
                                    <input type="hidden" name="incidence[assigned_to]" :value="Object.keys(TabSupport.data.assigned_to)[0]">
                                </template>
                                <template v-else>
                                    <template v-if="dataAssigned !== undefined">
                                        <label :class="'gh_text_field-label T15_b'"  style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('assign_to') + ':'}}</label>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 80%; min-width: 260px !important;">{{Object.values(dataAssigned).join()}}</label>
                                        <input type="hidden" name="incidence[assigned_to]" :value="Object.keys(dataAssigned).join()">
                                    </template>
                                    <template v-else>
                                        <label :class="'gh_text_field-label T15_b'"  style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('assign_to') + ':'}}</label>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 80%; min-width: 260px !important;">{{TabSupport.data.assignments !== undefined && TabSupport.data.assignments.assigned_to !== undefined ? TabSupport.data.assignments.assigned_to : ''}}</label>
                                        <input type="hidden" name="incidence[assigned_to]" :value="TabSupport.data.assignments !== undefined && TabSupport.data.assignments.id !== undefined ? TabSupport.data.assignments.id : ''">
                                    </template>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.add">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100%; min-width: 260px !important;">{{TabSupport.data.description}}</label>
                                </template>
                                <template v-else>
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description') + '*',
                                            class: 'T15_b',
                                            styles: 'margin-right: 10px; width: 125px; min-width: 125px !important; !important; text-align: right;',
                                        }"
                                        :datainput="{
                                            id: 'holiday_description',
                                            name: 'incidence[description]',
                                            required: true,
                                            value: description_create
                                        }"
                                        ref="refDescriptionCreate"
                                        @keyup="persistanceDescCreate"
                                    />
                                </template>
                            </div>
                            <template v-if="$root._route.params.add">
                                <input type="hidden" id="download-problem-screenshoot_hidden" name="incidence[screenshoot_content]">
                            </template>
                            <div class="gh-row-content">
                                <div class="collapsible_content-row separation-input_from_title container-screenshot" id="container-img-screenshoot" style="display: block; margin-bottom: 15px; margin-left: 143px;">
                                    <div class="container-screenshot" style=" width: 200px; max-height: 200px;">
                                        <a id="download-problem-screenshoot" :href="imgref" :download="imgdownload" class="T1 table-link">
                                            <img :src="imgurl" style=" width: 200px; max-height: 200px;" id="img-problem-screenshoot" />
                                            <div class="middle">
                                                <div class="T1 table-link">{{$t('download')}}</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.add">
                                    <div id="list_multiple_files_support_incidences" class="gh_data_selector" style="display: block; ">
                                        <div style="display: flex; width: 100%;">
                                            <div style="display: flex;margin-top: 1px; text-align: right;" >
                                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;" >{{$t('attachments')}}</label>
                                            </div>
                                            <div id="list_files_support_incidences" class=" T15" style="width: 100%;" v-if="TabSupport.data.attachments !== undefined && TabSupport.data.attachments.length !== 0">
                                                <div v-for="(i,index) in TabSupport.data.attachments" :key="index">
                                                    <div style="display: flex;" :style="index % 2 == 0 ? 'background-color: #FFFFFF;' : 'background-color: #E9F7FF;'">
                                                        <div class="gh_text_field-label gh_hidden_list-inline_content-value" data-row-index="1" style="width: 100%;">
                                                            <span :title="i.name">{{i.name}}</span>
                                                        </div>
                                                        <div class=" T13" style="display: flex;">
                                                            <div class="T13" style="width: 100px;">
                                                                <GhAction :dataction="{id:'_download_' + index, href: i.file_download, text: $t('download'), icon: require('../../../assets/gh_new_dessign/descargar.svg')}"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <GhMultiUpload
                                        :datalabel="{
                                            text: $t('attachments'),
                                            text_popup: $t('attachments'),
                                            input_text: $t('name'),
                                            textarea_text: $t('description'),
                                            attach_text: $t('attach_document'),
                                            content_warning: '',
                                            label_required: $t('attachments') ,
                                        }"
                                        :datablock="{
                                            id: 'add_multi_files_incidenece',
                                            name: 'incidence[files]',
                                            style: 'margin-left:65px;',
                                            class: 'T15_b',
                                            icon_attach: require('../../../assets/gh_new_dessign/attach.svg'),
                                            icon_trash: require('../../../assets/gh_new_dessign/trash.svg'),
                                            icon_download: require('../../../assets/gh_new_dessign/descargar.svg'),
                                            href: dispatchEvidence,
                                            required:true,
                                        }"
                                        refs="incidence_files_multiupload"
                                    />
                               </template>
                            </div>
                        </div>
                        <div v-if="!$root._route.params.add && haveData">
                            <div v-for="(item,index) in TabSupport.data.assignments" :key="index">
                                <div class="section_line">
                                    <div class="T15_b" style="padding-left:13px;">{{item.label}} </div>
                                </div>
                                <div class="gh-content-global">
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('user') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 260px !important;">{{item.soported_by !== undefined ? item.soported_by : ''}}</label>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: calc((100% - 169px) - (((100% - 165px) * 8) / 12)); text-align: right;">{{$t('date_start_task') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 260px !important;">{{item.start_date !== undefined ? item.start_date : ''}}</label>
                                    </div>
                                    <div class="gh-row-content">
                                        <template v-if="item.is_active == 0 || !item.actions.priority || $root._route.params.view">
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('priority') + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 260px !important;">{{priority_by_category !== undefinded && priority_by_category != '' ? priority_by_category : item.priority !== undefined ? Object.values(item.priority).join() : ''}}</label>
                                        </template>
                                        <template v-else>
                                            <div class="element_task_historic">
                                                <div style="width:50%; min-width: 260px !important;">
                                                    <GhDataSelector
                                                        :datablock="{
                                                            id: 'label_incidence_priority',
                                                            class: 'gh_text_field-label T15_b',
                                                            subtitle: $t('select_priority') + ':',
                                                            text: $t('priority') + '*',
                                                            label: $t('priority') + ':',
                                                            type: 'radio',
                                                            columns: 2,
                                                            style: 'margin-left: 55px;',
                                                            required: true,
                                                        }"
                                                        :datainput="{
                                                            id: 'value_certificate_activity',
                                                            name: 'incidence[priority]'
                                                        }"
                                                        :feed="getFeeds.feed_support_priority"
                                                        :selected_input="item.priority!==undefined ? item.priority : ''"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <label class="gh_text_field-label T15" :style="(item.is_active !== 0 || item.is_editable !== 0) && !$root._route.params.view ? 'width: calc((100% - 170px) - (((100% - 165px) * 8) / 12));' : 'width: calc((100% - 206px) - (((100% - 165px) * 8) / 12));'" style="text-align: right;">{{$t('date_end_task') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 260px !important;">{{item.end_date !== undefined ? item.end_date : ''}}</label>
                                    </div>
                                    <div class="gh-row-content">
                                        <template v-if="item.is_active == 0 || !item.actions.category || $root._route.params.view">
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('category') + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 260px !important;">{{item.category !== undefined ? Object.values(item.category).join() : ''}}</label>
                                        </template>
                                        <template v-else>
                                            <div class="element_task_historic">
                                                <div style="width:50%; min-width: 260px !important;">
                                                    <GhDataSelector
                                                        :datablock="{
                                                            id: 'label_incidence_category',
                                                            class: 'gh_text_field-label T15_b',
                                                            subtitle: $t('select_category') + ':',
                                                            text: $t('category') + '*',
                                                            label: $t('category') + ':',
                                                            type: 'radio',
                                                            columns: 2,
                                                            style: 'margin-left: 53px;',
                                                            required: true,
                                                        }"
                                                        :datainput="{
                                                            id: 'value_certificate_activity',
                                                            name: 'incidence[category]'
                                                        }"
                                                        :feed="getFeeds.feed_support_categories"
                                                        :selected_input="item.category !== undefined ? item.category : ''"
                                                        @accept="getPriorityAssigment"
                                                        ref="refSelectCategory"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="item.estimated_end_date && item.estimated_end_date !== undefined && item.estimated_end_date !== ''">
                                            <label class="gh_text_field-label T15" :style="(item.is_active !== 0 || item.is_editable !== 0) && !$root._route.params.view ? 'width: 110px;' : 'width: calc((100% - 213px) - (((100% - 165px) * 8) / 12));'" style="margin-right: 10px;  text-align: right;">{{$t('limite_date') + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 260px !important;">{{item.estimated_end_date !== undefined ? item.estimated_end_date : ''}}</label>
                                        </template>
                                    </div>
                                    <div class="gh-row-content" v-if="item.is_active == 1 && item.actions.assign && !$root._route.params.view">
                                        <GhDataSelector :key="refreshAssigment"
                                            :datablock="{
                                                id: 'label_incidence_asigned',
                                                class: 'gh_text_field-label T15_b',
                                                subtitle: $t('select_assignment') + ':',
                                                text: $t('assign_to') + '*',
                                                label: $t('assignment') + ':',
                                                type: 'radio',
                                                columns: 2,
                                                style: 'margin-left: 44px;',
                                                required: true,
                                            }"
                                            :datainput="{
                                                id: 'value_certificate_activity',
                                                name: 'incidence[assigned_to]'
                                            }"
                                            ref="AsignationDataSelector"
                                            @accept="asigntype(item.assigned_to)"
                                            :feed="getFeeds.feed_assigment_by_category"
                                            :selected_input="selectedAssignedTo !== undefined ? selectedAssignedTo : item.assigned_to !== undefined ? item.assigned_to : []"
                                        />
                                    </div>
                                    <div class="gh-row-content">
                                        <template v-if="item.is_active == 0 || item.is_editable == 0 || $root._route.params.view">
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('note') + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100%; min-width: 260px !important;">{{item.notes !== undefined ? item.notes : ''}}</label>
                                        </template>
                                        <template v-else>
                                            <GhTextarea
                                                :datalabel="{
                                                    text: $t('new_note') ,
                                                    class: 'gh_text_field-label T15',
                                                    styles: 'width: 124px; min-width: 124px !important; text-align: right; margin-right: 12px;',
                                                }"
                                                :datainput="{
                                                    id: 'exercise_open_question_statement',
                                                    name: 'incidence[note]',
                                                    value:  item.notes !== undefined ? $t(item.notes) : '',
                                                    styles:'height: 110px;',
                                                }"
                                            />
                                        </template>
                                    </div>
                                    <div class="gh-row-content">
                                        <template v-if="item.is_active == 0 || $root._route.params.view">
                                            <div id="list_multiple_files_support_incidences" class="gh_data_selector" style="display: block; ">
                                                <div style="display: flex; width: 100%;">
                                                    <div style="display: flex;margin-top: 1px; text-align: right;" >
                                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;" >{{$t('attachments')}}</label>
                                                    </div>
                                                    <div id="list_files_support_incidences" class=" T15" style="width: 100%;" v-if="item.attachments !== undefined && item.attachments.length !== 0">
                                                        <div v-for="(i,index) in item.attachments" :key="index">
                                                            <div style="display: flex;" :style="index % 2 == 0 ? 'background-color: #FFFFFF;' : 'background-color: #E9F7FF;'">
                                                                <div class="gh_text_field-label gh_hidden_list-inline_content-value" data-row-index="1" style="width: 100%;">
                                                                    <span :title="i.name">{{i.name}}</span>
                                                                </div>
                                                                <div class=" T13" style="display: flex;">
                                                                    <div class="T13" style="width: 100px;">
                                                                        <GhAction :dataction="{id:'_download_' + index, href: i.file_path, text: $t('download'), icon: require('../../../assets/gh_new_dessign/descargar.svg')}"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <GhMultiUpload
                                                :datalabel="{
                                                    id:'incidence label-form',
                                                    text: $t('attachments'),
                                                    text_popup: $t('attachments'),
                                                    input_text: $t('name'),
                                                    textarea_text: $t('description'),
                                                    attach_text: $t('attach_document'),
                                                    content_warning: '',
                                                    label_required: $t('attachments') ,
                                                }"
                                                :datablock="{
                                                    id: 'add_files_support_incidence',
                                                    name: 'incidence[files]',
                                                    style: 'min-width: 90px;margin-left: 66px;',
                                                    class: 'T15_b',
                                                    icon_attach: require('../../../assets/gh_new_dessign/attach.svg'),
                                                    icon_trash: require('../../../assets/gh_new_dessign/trash.svg'),
                                                    icon_download: require('../../../assets/gh_new_dessign/descargar.svg'),
                                                    href: dispatchEvidence,
                                                }"
                                                :defaultfiles="item.attachments !== undefined && item.attachments.length !== 0 ? item.attachments : []"
                                            />
                                        </template>
                                    </div>
                                    <div class="gh-row-content" v-if="item.returned !== undefined && item.returned !== ''">
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('returned') + ':'}}</label>
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100%; min-width: 260px !important;">{{item.returned !== undefined ? item.returned : ''}}</label>
                                    </div>
                                    <div class="gh-row-content" :style="item.is_active && item.actions.open_new_incidence && !$root._route.params.view ? 'padding-left: 65px;' : ''">
                                        <template v-if="item.is_active && item.actions.open_new_incidence && !$root._route.params.view">
                                            <GhAction
                                                :dataction="{
                                                    id: 'new_dependence_incidence',
                                                    text: $t('open_incidence_dependent'),
                                                }"
                                                @click="openDependence"
                                            />
                                            <label class="T15_b" style="margin-top: 3px;" id="label_incidence_reference">{{refDependence}}</label>
                                        </template>
                                        <template v-else-if="item.tickets_relations !== undefined && item.tickets_relations.length !== 0">
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 125px; min-width: 125px !important; text-align: right;">{{$t('incidence_dependent') + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 100%; min-width: 260px !important;">{{item.linked_ticket_reference !== undefined ? item.linked_ticket_reference : ''}}</label>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </GhNoDropSelector>
                <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                    <template v-if="TabSupport.data.buttons !== undefined && TabSupport.data.buttons.assign">
                        <GhButton
                            v-show="asignButton"
                            v-if="!$root._route.params.view && !$root._route.params.add"
                            :datainput="{
                                id: 'btn_close', class: 'T19 container-md_button_content',
                                text: $t('assign')
                            }"
                            @click="save('assign')"
                        />
                    </template>
                    <GhButton
                        v-if="!$root._route.params.view && !$root._route.params.add && (TabSupport.data.buttons !== undefined && TabSupport.data.buttons.return == 1 ? true : false)"
                        :datainput="{
                            id: 'btn_return_incidence',
                            class: 'T19 container-md_button_content',
                            text: $t('return')
                        }"
                        @click="resolveKey = false; Global.openPopUp( 'resolve_crm_incidence')"
                    />
                    <GhButton
                        v-if="!$root._route.params.view && !$root._route.params.add && (TabSupport.data.buttons !== undefined && TabSupport.data.buttons.resolve == 1 ? true : false)"
                        :datainput="{
                            id: 'btn_resolve_incidence',
                            class: 'T19 container-md_button_content',
                            text: $t('check')
                        }"
                        @click="resolveKey = true; Global.openPopUp( 'resolve_crm_incidence')"
                    />
                    <GhButton
                        v-if="!$root._route.params.view && !$root._route.params.add && (TabSupport.data.buttons !== undefined && TabSupport.data.buttons.close_incidence == 1 ? true : false)"
                        :datainput="{
                            id: 'btn_close_incidence',
                            class: 'T19 container-md_button_content',
                            text: $t('close_incidence')
                        }"
                        @click="save('close')"
                    />
                    <GhButton
                        v-show="!asignButton"
                        v-if="!$root._route.params.view && ($root._route.params.add || (TabSupport.data.buttons !== undefined && TabSupport.data.buttons.finish == 1 ? true : false))"
                        :datainput="{
                            id: 'btn_close',
                            class: 'T19 container-md_button_content',
                            text: $t('finish')
                        }"
                        @click="save('finalize')"
                    />
                    <GhButton
                        v-if="!$root._route.params.view && !$root._route.params.add"
                        :datainput="{
                            id: 'btn_save_incidence',
                            class: 'T19 container-md_button_content',
                            text: $t('save')
                        }"
                        @click="save('')"
                    />
                    <GhButton
                        :datainput="{
                            id: 'btn_close',
                            class: 'T19 container-md_button_content',
                            text:$root._route.params.add ? $t('close') : $t('back')
                        }"
                        @click="$root._route.params.add ? Global.windowClose() : router.go(-1)"
                    />
                </div>
            </form>
        </div>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'resolve_crm_incidence',
                title: $t(resolveKey ? 'check_2' : 'return'),
                type: 'add',
                style: 'width: 50%; min-width:920px;'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <form id="form_resolve_crm_incidence">
                    <div class="gh-content-global">
                        <template v-if="resolveKey">
                            <div class="gh-row-content" style="display: flex;">
                                <GhChecks
                                    v-bind:datainput="{
                                        id: 'resolve_true',
                                        value: 1,
                                        type: 'radio',
                                        name: 'incidence[is_resolved]',
                                        style: 'margin-left:48px; cursor:pointer;',
                                        selected: true
                                    }"
                                    v-bind:datalabel="{
                                        style: 'width: auto;',
                                        class: 'T15 input_label',
                                        id: 'label_resolve_true',
                                        text: $t('resolved')
                                    }"
                                />
                                <GhChecks
                                    v-bind:datainput="{
                                        id: 'resolve_false',
                                        value: 0,
                                        type: 'radio',
                                        name: 'incidence[is_resolved]',
                                        style: 'margin-left:48px; cursor:pointer;',
                                        selected: false
                                    }"
                                    v-bind:datalabel="{
                                        style: 'width: auto;',
                                        class: 'T15 input_label',
                                        id: 'label_resolve_false',
                                        text: $t('no_resolved')
                                    }"
                                />
                            </div>
                        </template>
                        <div class="gh-row-content" style="display: flex;">
                            <GhTextarea
                                :datalabel="{
                                    text: $t('comment'),
                                    class: 'gh_text_field-label T15',
                                    styles: 'margin-left: 48px;width: auto;padding-right: 10px;',
                                }"
                                :datainput="{
                                    id: 'comment_crm_resolve',
                                    name: 'incidence[comment]',
                                    value: '',
                                    required: !resolveKey,
                                    styles: 'width: 100%'
                                }"
                            />
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_save_flow',
                        text: $t('save')
                    }"
                    @click="resolveKey ? resolve() : returnButton()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_flow',
                        text: $t('close')
                    }"
                    @click="Global.closePopUp( 'resolve_crm_incidence')"
                />
            </template>
        </GhPopUp>
    </div>
</template>
<script>
    import GhAction from 'fe-gh-action-lib'
    import GhMultiUpload from 'fe-gh-multi-upload-lib'
    import GhTextarea from 'fe-gh-textarea-lib'
    import GhDataSelector from 'fe-gh-data-selector-lib'
    import GhButton from 'fe-gh-button-lib'
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import GhLocation from 'fe-gh-location-lib'
    import {mapState} from "vuex";
    import html2canvas from "html2canvas";
    import GhPopUp from "fe-gh-popup-lib";
    import GhChecks from "fe-gh-checks-lib";
    import {loadLanguageAsync} from "@/main";
    export default {
        name: "incidence_template",
        components:{
            GhChecks,
            GhPopUp,
            GhNoDropSelector,
            GhButton,
            GhDataSelector,
            GhTextarea,
            GhMultiUpload,
            GhAction,
            GhLocation
        },
        computed:{
            ...mapState(['TabSupport', 'getFeeds', 'Login'])
        },
        data(){
            return{
                haveData: false,
                putImage: false,
                imgurl: '',
                asignButton: false,
                imgdownload: '',
                imagenComoBase64: '',
                refDependence: '',
                imgref: '',
                priority_by_category: '',
                assigment_by_category: '',
                dispatchEvidence: 'easyUpload',
                dataAssigned: [],
                selectedAssignedTo: [],
                refreshAssign: 0,
                refreshAssigment: 0,
                description_create: '',
                resolveKey: false
            }
        },
        async beforeMount(){
            var lang = JSON.parse(localStorage.getItem('Language-selected-select_lang')).id;
            loadLanguageAsync(lang);
            await this.$store.dispatch('getPetitionData', {
                id: this.$root._route.params.id,
                add: this.$root._route.params !== undefined && this.$root._route.params.add !== undefined ? this.$root._route.params.add : false,
                origin: this.$root._route.params.origin_id !== undefined
            },{root:true});

            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeedSupportCategory', '', {root: true});
                await this.$store.dispatch('getFeedSupportPriority', '', {root: true});
                await this.$store.dispatch('getFeedSupportAsigned', '', {root: true});
            }
            if(!this.$root._route.params.add){
                this.imgurl = this.$store.state.TabSupport.data && this.$store.state.TabSupport.data.image && this.$store.state.TabSupport.data.image.src ? this.$store.state.TabSupport.data.image.src : '';
                this.imgdownload = this.$store.state.TabSupport.data && this.$store.state.TabSupport.data.image && this.$store.state.TabSupport.data.image.href?this.$store.state.TabSupport.data.image.href : '';
                this.imgref = this.$store.state.TabSupport.data && this.$store.state.TabSupport.data.image && this.$store.state.TabSupport.data.image.download?this.$store.state.TabSupport.data.image.download : '';
            }

            if(this.TabSupport.data.assignments !== undefined && this.TabSupport.data.assignments.length !== 0){
                this.TabSupport.data.assignments.forEach((i) => {
                    if(i.is_active && i.tickets_relations !== undefined && i.tickets_relations.length !== 0){
                        i.tickets_relations.forEach((x) => {
                            this.refDependence += x.ticket_reference + ', ';
                        });

                        this.refDependence = this.refDependence.substring(0, this.refDependence.length - 2);
                    }
                });
            }

            // this.asigntype();
            this.getFeeds.feed_assigment_by_category = [];

            this.haveData = true;

            if(this.$root._route.params.add) {
                document.getElementById('text_tab_location').getElementsByClassName('subtitulo-icon')[0].firstChild.src = require('../../../assets/gh_new_dessign/floating.svg');
            }

            if(!this.putImage){
                this.putImage = true
                this.$root._route.params.add ? this.capture() : '';
            }

            this.$nextTick(() => {
                this.getPriorityAssigment();
            });
        },
        methods:{
            persistanceDescCreate(){
                this.description_create = this.$refs.refDescriptionCreate.$el.lastChild.value;
            },
            async getAssign(){
                await this.$store.dispatch('getSupportGroupsCategory', {
                    id: Object.keys(this.$refs.RefSelectorCategory.checkdata)[0]
                }, {root: true}).then((response) => {
                    // this.dataAssigned
                    if(response !== undefined){
                        this.dataAssigned = response;
                        this.refreshAssign++;
                    }
                });
            },
            async getPriorityAssigment(){
                if(this.$refs.refSelectCategory !== undefined){
                    var category = Object.keys(this.$refs.refSelectCategory[0].checkdata)[0];

                    await this.$store.dispatch('getPriorityByCategory', {category: category}, {root: true});
                    await this.$store.dispatch('getAssigmentByCategory', {
                        category: category,
                        id: this.$root._route.params.id
                    }, {root: true});

                    this.priority_by_category = Object.values(this.getFeeds.feed_priority_by_category)[0];
                    this.selectedAssignedTo = this.getFeeds.feed_assigment_by_category.length === 1 ? this.getFeeds.feed_assigment_by_category : undefined;
                    this.refreshAssigment++;

                    var that = this;
                    setTimeout(function () {
                        that.$refs.AsignationDataSelector[0].save();
                        that.$refs.AsignationDataSelector[0].setSummary();
                    }, 250);
                }
            },
            openDependence(){
                this.Global.windowOpen(this.Global.openSecondWindow('incidence_new_by_origin',{add:true, origin_id:this.$root._route.params.id}),'new_incidence')
            },
            asigntype(item){
                var selected= item ? Object.keys(item)[0] : '';
                var preselected = Object.keys(this.$refs.AsignationDataSelector[0].$data.checkdata)[0];
                this.asignButton = selected !== '' && selected !== preselected ? true : false;
            },
            async save(state){
                if(this.Global.checkRequired('form_support_incidence') == 'OK') {
                    const formData = new FormData(document.getElementById('form_support_incidence'));

                    if(state == 'finalize' && this.$root._route.params.origin_id){
                        await this.$store.dispatch('setSupportIncidence', {formData: [...formData], state: state , id: this.$root._route.params.id, origin_id: this.$root._route.params.origin_id}, {root: true});
                    }else{
                        await this.$store.dispatch('setSupportIncidence', {formData: [...formData], state: state , id: this.$root._route.params.id}, {root: true});
                    }
                    this.$root._route.params.add ? this.Global.windowClose() : this.router.go(-1);
                }
            },
            resolve(){
                const formData = new FormData(document.getElementById('form_resolve_crm_incidence'));
                const formData2 = new FormData(document.getElementById('form_support_incidence'));
                this.$store.dispatch('setResolveIncidence', {formData: [...formData, ...formData2], id: this.$root._route.params.id}, {root: true});
                this.$root._route.params.add ? this.Global.windowClose() : this.router.go(-1);
                this.Global.closePopUp( 'resolve_crm_incidence');
            },
            async returnButton(){
                if(this.Global.checkRequired('form_resolve_crm_incidence') === 'OK') {
                    const formData = new FormData(document.getElementById('form_resolve_crm_incidence'));
                    const formData2 = new FormData(document.getElementById('form_support_incidence'));
                    this.$store.dispatch('setReturnIncidence', {formData: [...formData, ...formData2], id: this.$root._route.params.id}, {root: true});
                    this.Global.closePopUp( 'resolve_crm_incidence');
                    this.router.push({name: 'support'});
                }
            },
            capture() {
                const captureElement = window.opener.document.getElementsByTagName("body")[0];
                html2canvas(captureElement)
                    .then(canvas => {
                        canvas.style.display = 'none'
                        document.body.appendChild(canvas)
                        return canvas
                    }).then(canvas => {
                        const image = canvas.toDataURL('image/png') ;
                        const image_octect = image.replace('image/png', 'image/octet-stream') ;

                        var imagenComoBase64 = encodeURIComponent(image);
                        this.imagenComoBase64 = JSON.stringify(imagenComoBase64);

                        document.getElementById('img-problem-screenshoot').src = image_octect;
                        document.getElementById('download-problem-screenshoot').href = image_octect;
                        document.getElementById('download-problem-screenshoot').download = 'captura_incidencia.png';
                        document.getElementById('download-problem-screenshoot_hidden').value = this.imagenComoBase64;
                    });
            }
        }
    }
</script>

<style>
    #label_incidence_priority_data_select_SUMMARY{
      width: 500px;
      max-width: 500px;
    }
    #label_incidence_category_data_select_SUMMARY{
      width: 500px;
      max-width: 500px;
    }
    .selector-min-width-49> div > div {
        min-width: 49% !important;
    }
    .selector-min-width-51> div > div {
        min-width: 51% !important;
    }
    .element_task_historic > div > div{
      display: flex;
      width: calc((100% - 160px) - (((100% - 280px) * 8) / 15));
    }
    .element_task_historic > div > div > div{
      margin-bottom: 0px !important;
    }

    #id_category_add > div > div{
      margin-bottom: 0px !important;
    }

    .element_task_historic:nth-child(2) > label{
      margin-left: 20px;
    }
    .container-screenshot {
        position: relative;
        width: 50%;
    }
    #container-img-screenshoot > div {
      margin-left: 135px;
    }
    .container-screenshot img {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: .5s ease;
        backface-visibility: hidden;
    }

    .container-screenshot .middle {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    }

    .container-screenshot:hover img {
        opacity: 0.5;
        cursor: pointer;
    }

    .container-screenshot:hover .middle {
        opacity: 1;
        cursor: pointer;
    }

    .container-screenshot .text {
        color: black;
        font-size: 16px;
        padding: 16px 32px;
    }

    #add_files_support_incidence_SUMMARY{
        margin-right: 0px !important;
    }
</style>
