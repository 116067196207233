<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_assign',
            title: $t('assing_users_to_program'),
            type: 'add',
            style: 'width: 40%; min-width: 940px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;max-height: 40vh; min-height: 2vh; overflow-y: auto !important;',
            text: $t('assing_users_to_program') + ' (' + getterAssignItineraryTodo.title + ')'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_todo_assign" :key="'REFRESHER_ASSIGN' + refresher">
                <div class="gh-content-global">
                    <div v-for="(program, keyProgram) in getterAssignItineraryTodo.data" :key="'PROGRAM' + keyProgram" :style="keyProgram % 2 ? 'background-color:  var(--pallette-three) !important' : ''" style="width: 100%;">
                        <div class="gh-row-content" style="padding-bottom: unset">
                            <div>
                                <div style="display: flex; width: 100%; padding-top: 4px; padding-bottom: 4px; padding-left: 5px;">
                                    <label :class="Object.values(program.permissions).length !== 0 ? 'T14' : 'T15_b'">{{program.name}}</label>
                                    <div style="cursor: pointer; margin-left: 10px;" v-if="Object.values(program.permissions).length !== 0" :key="'TOGGLE_' + keyProgram + '_' + refresh">
                                        <div :class="toggleAction[keyProgram] === true ? 'gh_data_selector-more T19' : 'gh_data_selector-less T19'" style="white-space: nowrap;" @click="setToggleItem(keyProgram);">{{toggleAction[keyProgram] === true ? '[ - ]' : '[ + ]'}}</div>
                                    </div>
                                </div>
                                <div style="display: flex; width: 400px !important; padding-top: 3px;">
                                    <template v-for="(data, key) in getFeeds.feed_assign_perms">
                                        <div :key="'CHECKS_PERMS' + key" style="display: flex;flex-direction: row-reverse; flex-wrap: revert;">
                                            <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 10px;">{{data}}</label>
                                            <GhChecks
                                                :datalabel="{text: '',style:'',class:'',id:''}"
                                                :datainput="{
                                                    selected: program.permissions.includes(key),
                                                    type: 'checkbox',
                                                    id: 'input_assign_support',
                                                    value: key,
                                                    name: 'assign[' + program.id + '][assign][]'
                                                }"
                                                :ref="'refCheck' + program.id + key"
                                                @click="checkItinerary(program, key)"
                                            />
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div v-show="toggleAction[keyProgram] === true" style="padding-bottom: 10px;">
                            <div class="gh-row-content" style="padding-bottom: unset; display: unset;">
                                <template v-for="(selected, keySelected) in program.programs">
                                    <template v-if="selected !== undefined && selected.length !== 0">
                                        <div :key="'SELECTEDS_' + keySelected + program.id">
                                            <div style="padding-top: 4px; padding-bottom: 4px; padding-left: 5px; display: flex;">
                                                <label class="gh_text_field-label T15_b" style="width: 120px !important;">
                                                    {{getFeeds.feed_assign_perms[keySelected] + ': '}}
                                                </label>
                                                <div style="display: grid; width: 100%;">
                                                    <template v-for="(item, keyItem) in selected">
                                                        <label class="gh_text_field-label T15" :key="'ITEM_' + program.id + keyItem">{{item.name}}</label>
                                                        <input type="hidden" :key="'ITEM_HIDDEN_' + program.id + keyItem" :name="'assign[' + program.id + '][permissions][' + keySelected + '][]'" :value="item.id"/>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div :key="'TREE_ITINERARY' + refreshItinerary">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'checkbox',
                        id: 'label_user_assign_permission',
                        label: 'aaa',
                        title: $t('assign') + ' ' + $t('to_program').toLowerCase(),
                        text: $t('select_itinerary') + ':',
                        name: 'assign[user_itinerary][]',
                        id_value: 'id',
                        name_label: 'name',
                        child_key: 'children',
                        required: false,
                        checkparentdata: true,
                        checkAll: true,
                        style: '',
                        label_style: 'width: 200px; display:none; text-align: end;',
                        popupStyle: 'min-width: 900px; width: 55%;',
                        allowCheckAutoParent: true
                    }"
                    ref="refTreeItinerary"
                    :selected_input="[]"
                    :items="getterTreeItinerary !== undefined && getterTreeItinerary.data !== undefined ? getterTreeItinerary.data : []"
                    @close="closeSelectionItinerary()"
                    @accept="aceptSelectionItinerary()"
                />
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_accept_assign',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="toggleAction = []; setAssign()"
            />
            <GhButton
                :datainput="{
                    id: 'button_close_assign',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="toggleAction = []; Global.closePopUp('popup_assign');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: "popup_assign",
        props: ['refresher'],
        computed: {
            ...mapState(['getFeeds']),
            ...mapGetters(['getterTreeItinerary', 'getterAssignItineraryTodo'])
        },
        components: {
            GhPopUp,
            GhChecks,
            GhTreeSelectorBlock,
            GhButton
        },
        data(){
            return {
                toggleAction: [],
                actualUser: '',
                actualPermission: '',
                refresh: 0,
                refreshItinerary: 0
            }
        },
        watch: {
            'getterAssignItineraryTodo.data'(data) {
                data.forEach((item, key) => {
                    if(this.toggleAction[key] === undefined){
                        this.toggleAction[key] = false;
                    }
                });
            }
        },
        methods: {
            setToggleItem(key){
                this.toggleAction[key] = !this.toggleAction[key];
                this.refresh++;
            },
            async checkItinerary(user, permission_id){
                this.refreshItinerary++;

                if(this.$refs['refCheck' + user.id + permission_id][0].selected) {
                    let that = this;
                    that.actualUser = user.id;
                    that.actualPermission = permission_id;

                    setTimeout(function () {
                        that.$refs['refTreeItinerary'].statePopup = true;
                        that.Global.openPopUp('label_user_assign_permission-Popup');
                    }, 100);
                }else{
                    await this.$store.dispatch('resetAssignPerm', {
                        user_id: user.id,
                        permission_id: permission_id
                    }, {root: true});
                }
            },
            async aceptSelectionItinerary(){
                await this.$store.dispatch('setAssignPerm', {
                    user_id: this.actualUser,
                    permission_id: this.actualPermission,
                    selected: this.$refs['refTreeItinerary'].datasaved
                }, {root: true});
            },
            closeSelectionItinerary(){
                this.$refs['refCheck' + this.actualUser + this.actualPermission][0].checkUncheck();
            },
            async setAssign(){
                const formData = new FormData(document.getElementById('form_todo_assign'));

                await this.$store.dispatch('setAssignItineraryTodo', {
                    formData: [...formData],
                    id: this.getterAssignItineraryTodo.id,
                    from: this.getterAssignItineraryTodo.from,
                    agrupator_id: this.getterAssignItineraryTodo.agrupator_id
                }, {root: true}).then((response) => {
                    if(response === 200){
                        this.Global.closePopUp('popup_assign');
                    }
                });
            }
        }
    }
</script>

<style>
    [id^="label_user_assign_"][id$="_selector_label"]{
        width: 150px;
        min-width: 150px !important;
        text-align: right;
    }

    #label_user_assign_permission-Summary{
        display: none !important;
    }
</style>