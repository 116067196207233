var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhCECard',{key:'CARD' + _vm.keyCard,attrs:{"block":{
        image: '',
        progressBar: true,
        colors: [
            '#0099CC',
            '#005392'
        ],
        colorsHover: [
            '#B7C0C5',
            '#B7C0C5'
        ]
    },"data":_vm.itemCard},scopedSlots:_vm._u([{key:"extraContent",fn:function(){return [(_vm.showButton)?[_c('div',{staticClass:"centered",staticStyle:{"padding-top":"15px","padding-bottom":"15px"}},[_c('GhButton',{staticClass:"button-questionnaire-secondary",attrs:{"datainput":{
                        id: 'btn_assign_' + _vm.itemCard.id,
                        text: _vm.itemCard.finished ? _vm.$t('finished') : _vm.itemCard.assigned ? _vm.$t('assigned') : _vm.$t('assign'),
                        style: 'margin-right: 0px!important'
                    },"disabled":_vm.itemCard.finished},on:{"click":function($event){return _vm.assign(_vm.itemCard, $event)}}}),(_vm.itemCard.weighting && !_vm.itemCard.finished)?[_c('GhButton',{staticClass:"button-questionnaire-secondary",staticStyle:{"margin-left":"10px"},attrs:{"datainput":{
                            id: 'btn_weighting_' + _vm.itemCard.id,
                            text: _vm.$t('weighting'),
                            style: 'margin-right: 0px!important'
                        }},on:{"click":function($event){return _vm.weighting(_vm.itemCard, $event)}}})]:_vm._e()],2)]:_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }